//MODULES
@import './modules/mixins';
@import './admin/_variables';

body{
  // background: url('/images/bg.jpg');
  // background-size: contain;
}

.auth{
  height: 100vh;

  .auth-bg{
    background-color: $primary;
    height: 100vh;

    img{
      &.bottom-right{
        width: 200px;
        margin: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        float: right;
        position: absolute;
      }

      &.center{
        width: 88%;
        display: flex;
        margin: 6% auto;
        z-index: 999;
        position: relative;
      }

      &.middle-left{
        width: 260px;
        margin: 234px 0 0 0;
        left: 0;
        z-index: 0;
        float: left;
        position: absolute;
      }

      &.middle-right{
        width: 260px;
        margin: 234px 0 0 0;
        right: 0;
        z-index: 0;
        float: right;
        position: absolute;
      }

      &.top-left{
        width: 220px;
        height: 160px;
        margin: -10px 0 0 0;
        left: 0;
        z-index: 0;
        float: left;
        position: absolute;
      }

      &.top-right{
        width: 260px;
        margin: -10px 0 0 0;
        right: 244px;
        z-index: 0;
        float: right;
        position: absolute;
      }
    }

  }

  .auth-form{
    background-color: $primary;
    height: 100vh;
    padding: 0 60px 0 100px;
  }

  .auth-message{
    color: $white;
    text-align: center;
    font-size: 18px;

    a{
      color: $white;
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      margin: 0;
    }
  }

  .card{
    background-color: $white !important;

    .card-body{
      button{
        color: $secondary;
        width: 50%;
        margin: 0 25%;
        display: block;
        border-radius: 30px;
      }

      .register{
        a{
          width: 50%;
          margin: 20px 25%;
          display: block;
          border-radius: 30px;
        }
        
        h5{
          color: $primary;
          text-align: center;
          font-size: 26px;
        }
      }
    }
    .card-header{
      h4{
        text-align: center;
        margin: 0;
        color: $gray;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  .login-brand{
    margin: 12% 0 0 0;

    img{
      width: 60%;
      margin: 0 0 40px 0;
      padding: 0;
    }
  }
}

.home{
  background-color: $primary !important;

  .items-top{
    a{
      color: $white;
    }
  }

  .logo{
    img{
      display: flex;
      margin: 20% auto;
      width: 300px;
    }
  }
}

//####################
//RESPONSIVE
@media screen and (max-width: 1024px){
  .auth{
    .auth-bg{
      display: none;
    }

    .auth-form{
      padding: 0 25px;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px){
  
}

@media screen and (min-width: 1281px) and (max-width: 1366px){
  
}

@media screen and (min-width: 1367px) and (max-width: 1440px){
  
}

@media screen and (min-width: 1441px) and (max-width: 1600px){
  
}

@media screen and (min-width: 1601px){
  
}