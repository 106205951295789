//Typography
$font-family-sans-serif: 'Nunito', sans-serif;

$primary: #361c4b;
$primaryHover: #7b30b7;

$purple: #7b30b7;

$secondary: #FFFFFF;
$secondaryHover: #FFFFFF;

$gray: #565C65;

$black: #212121;
$white: #FFFFFF;

$green: #43A047;

a{
  color: $primary;
}

a, .btn{
  &:hover{
    cursor: pointer;
  }
}

.bg-primary{
  background-color: $primary !important;
}

.btn-primary{
  @include transition(.5s ease all);
  background-color: $primary !important;
  border: 1px solid $primary !important;

  &:hover{
    background-color: $primaryHover !important;
    border: 1px solid $primaryHover !important;
  }
}

.btn-outline-primary{
  color: $primary !important;
  border: 1px solid $primary !important;

  &:hover{
    background-color: $primary !important;
    border: 1px solid $primary !important;
    color: $white !important;
  }
}

.card{
  &.card-primary{
    border-top: 2px solid $primary;
  }

  .card-header{
    .btn-right{
      position: absolute;
      float: right;
      right: 15px;
    }

    h4{
      color: $primary;
    }
  }
}

.text-primary{
  color: $primary !important;
}